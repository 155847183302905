export const EMAIL_REQUIRED = "Please enter the email";
export const PASSWORD_REQUIRED = "Please enter the password";
export const EMAIL_PASSWORD_REQUIRED = "Please enter email and password";
export const FIRST_NAME_REQUIRED = "Please enter First Name";
export const PHOTO_REQUIRED = "Please upload your photo";
export const FIRST_NAME_LIMIT_ERROR = 'First Name should be less than 30 characters';
export const LAST_NAME_REQUIRED = "Please enter Last Name";
export const LAST_NAME_LIMIT_ERROR = 'Last Name should be less than 30 characters';
export const CONFIRM_PASSWORD_REQUIRED = "Please confirm your password";
export const PASSWORD_CONFIRM_PASSWORD_MATCH = "Password and confirm password do not match";
export const OLD_PASSWORD_REQUIRED = "Please enter Old Password";
export const NEW_PASSWORD_REQUIRED = "Please enter New Password";
export const SAME_OLD_NEW_PASS = "Old Password and New Password cannot be the same";
export const NEW_CONFIRM_PASSWORD_REQUIRED = "Please confirm your Password";
export const INVALID_PASSWORD = "Please enter password between 6 to 12 characters";
export const OTP_REQUIRED = "Please enter OTP to proceed";
export const VALID_OTP = "Please enter 4 Digit OTP to proceed";
export const OTP_SENT_SUCCESS = "Email Validation OTP has been sent successfully";
export const OTP_SENT_FAILURE = "Please retry after 30 seconds.";
export const SOMETHING_WENT_WRONG = "We seem to have hit a snag. Please retry in a while.";
export const IMAGE_REQUIRED = "Please select an image";
export const NAME_REQUIRED = "Please enter the Name";
export const UNIQUE_CODE_REQUIRED = "Please enter the 6 Digit Unique Code";
export const LATLNG_REQUIRED = "Please enter the LAT/LNG";
export const PHONE_NUMBER_REQUIRED = "Please enter the phone number";
export const PHONE_NUMBER_INCORRECT = "Please enter correct phone number";
export const MESSAGE_REQUIRED = "Please enter the message";
export const INCORRECT_EMAIL = "The email must be a valid email address";
export const INCOMPLETE_PROFILE_ERROR = "Please complete your profile!";
export const USER_REGISTRATION_ISSUE = "Issue with User Registration";
export const VALID_LINK = "Please enter a valid link";
export const BUTTON_TEXT_OK = "OK";
export const EXCEL_TEMPLATE = "userImportTemplate";
export const DATEPICKER_PLACEHOLDER = "DD/MM/YYYY";
export const LOGIN_FAIL = "Unable to find user with this Email and Password, Please create an account."
export const LOGIN_SUCCESS = "Logged In Successfully"
export const LOGIN_AGAIN = "You have been logged out. Please log in again"
export const RESET_PASSWORD_SUCCESS = "Password Reset Successfully"
export const RESET_PASSWORD_FAIL = "Password Reset Failed"
export const BROWSER_STORAGE_ERROR = "Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.";
export const DOB_REQUIRED = 'Please enter Date of Birth';
export const ADDRESS_REQUIRED = 'Please enter Address';
export const COLOR_REQUIRED = 'Please Choose Primary Color';
export const REMARKS_REQUIRED = 'Please enter Remarks';

export const minimumLengthRequired = (length, value = "Characters") => {
    return `Minimum ${length} ${value} required`
}
export const maximumLengthRequired = (length, value = "Characters") => {
    return `Maximum ${length} ${value} required`
}
export const maximumLengthAllowed = (length, value = "Characters") => {
    return `Maximum ${length} ${value} Allowed`
}

export const PAGE_NOT_ACCESSABLE = "You cannot access this page";
export const CANNOT_VIEW = (moduleName) => `You cannot view ${moduleName}`;
export const CANNOT_ADD = (moduleName) => `You cannot add ${moduleName}`;
export const CANNOT_UPDATE = (moduleName) => `You cannot update ${moduleName}`;
export const CANNOT_DELETE = (moduleName) => `You cannot delete ${moduleName}`;

export const ROLE_REQUIRED = `Please select Role`;
export const ROLE_NAME_REQUIRED = `Please enter Role name`;
export const PASSWORD_FIELD_INFO = "Leave Password field untouched if you don't want to change Password";
export const SELECT_DATE_RANGE = "Select date range";
export const INVALID_NAME_FORMAT = "Invalid name format";
export const INVALID_UNIQUE_CODE = "Invalid unique code";
export const ROLL_NUMBER_REQUIRED = "Please enter Roll Number";
export const EMP_ID_REQUIRED = "Please enter Employee ID";
export const DEPARTMENT_REQUIRED = "Please select a Department";
export const INVALID_DEPARTMENT = "Invalid department";
export const INVALID_COLOR = "Invalid color";
export const LOGIN_TYPES = "Select Login Type";
export const ATTENDANCE_TYPES = "Select Attendance Type";
export const EXPIRY_DATE = "Select Expiry Date";
export const DESC_REQUIRED = "Please enter Description";
export const HOD_REQUIRED = "Please select HOD";
export const LATLNG_INVALID = "Please enter Lat/Lng in format of 'lat, lng'";
export const SELECT_USERS = `Select User(s) to proceed`;
export const VALUE_REQUIRED = name => (`Enter ${name}'s Value`);
export const VALID_EXCEL_FILE = `Please select a valid Excel File`;
