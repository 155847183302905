export const MASTER_DATA = "master_data";
export const SYSTEM_DATA = "system_data";

export const AUTH = 'auth';
export const USER_DATA = 'user_data';

export const DEPARTMENTS = 'depts';
export const HOD = 'hod';

export const ROLES = 'roles';

export const USER_ROLE = 'r';
export const PERMISSIONS = 'p';

export const PROFILE = 'profile';

export const YEARS_TOTAL = 40;
export const RUPEE = "₹";
export const SKILL_THRESHOLD = 1;
