import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';

export function login(reqData, applyUniqueCode) {
  return apiProvider.post(urlConstants.LOGIN, reqData, applyUniqueCode);
}

export function logout(reqData) {
  return apiProvider.post(urlConstants.LOGOUT, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function changePassword(reqData, applyUniqueCode) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData, applyUniqueCode)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function masterData() {
  return apiProvider.getAll(urlConstants.MASTER_DATA);
}

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export function getDepartmentList(reqData) {
  return apiProvider.post(urlConstants.DEPARTMENTS_ENDPOINT, reqData);
}

export function getDepartmentById(id) {
  return apiProvider.getAll(urlConstants.DEPARTMENTS_ENDPOINT + id);
}

export function upsertDepartment(reqData) {
  return apiProvider.post(urlConstants.DEPARTMENTS_ENDPOINT + '/upsert', reqData);
}

export function assignUsers(reqData) {
  return apiProvider.post(urlConstants.ASSIGN_USERS, reqData);
}

export function getClientList(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ENDPOINT, reqData, false);
}

export function editClient(id, reqData) {
  return apiProvider.put(urlConstants.CLIENT_ENDPOINT + '/' + id, reqData, false);
}

export function searchClient(query) {
  return apiProvider.getAll(urlConstants.CLIENT_ENDPOINT + query);
}

export function getClientById(id) {
  return apiProvider.getAll(urlConstants.CLIENT_DETAILS + id, '', false);
}

export function addClient(reqData) {
  return apiProvider.post(urlConstants.ADD_CLIENT, reqData, false);
}

export function getClientUsersList(reqData) {
  return apiProvider.post(urlConstants.CLIENT_USERS, reqData);
}

export function getClientUserById(id) {
  return apiProvider.getAll(urlConstants.CLIENT_USERS + '/' + id);
}

export function createClientUser(reqData) {
  return apiProvider.post(urlConstants.CLIENT_USERS + '/create', reqData);
}

export function updateClientUser(id, reqData) {
  return apiProvider.put(urlConstants.CLIENT_USERS + '/' + id, reqData);
}

export function getRolesList(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ROLES, reqData);
}

export function addRole(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ROLES + '/upsert', reqData);
}

export function uploadImages(reqData) {
  return apiProvider.post(urlConstants.UPLOAD_IMAGE, reqData);
}

export function deleteRole(id) {
  return apiProvider.deleteAPI(urlConstants.CLIENT_ROLES + '/' + id);
}

export function getRolePermissions(id) {
  return apiProvider.getAll(urlConstants.ROLE_PERMISSION + '/' + id);
}

export function updatePermission(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ROLE_PERMISSION, reqData);
}

export function upsertClientRole(reqData) {
  return apiProvider.put(urlConstants.CLIENT_ROLES, reqData);
}

export function getAttendanceList(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ATTENDANCE, reqData);
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.CLIENT_ROLES, reqData);
}

export function getProfile(reqData) {
  return apiProvider.getAll(urlConstants.CLIENT_ROLES, reqData);
}

export function getSettingsList(reqData) {
  return apiProvider.post(urlConstants.CLIENT_SETTINGS, reqData);
}

export function updateSetting(id, reqData) {
  return apiProvider.put(urlConstants.CLIENT_SETTINGS + id, reqData);
}

export function importExcel(reqData) {
  return apiProvider.post(urlConstants.IMPORT_EXCEL, reqData);
}

export function getDashboardStats() {
  return apiProvider.getAll(urlConstants.DASHBOARD, '', false);
}

export function getDashboardReportData(query, reqData) {
  return apiProvider.post(urlConstants.DASHBOARD_REPORTS + query, reqData);
}

export function changeUserStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_USER_STATUS + id, reqData);
}

export function changeClientStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_CLIENT_STATUS + id, reqData);
}

export function changeDepartmentStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_DEPARTMENT_STATUS + id, reqData);
}

export function updateUserNotMatchedImage(id) {
  return apiProvider.post(urlConstants.UPDATE_USER_NOT_MATCHED_IMAGE + id);
}

export function getReportsList() {
  return apiProvider.getAll(urlConstants.REPORTS);
}

export function getReportData(id, reqData) {
  return apiProvider.post(urlConstants.REPORTS + '/' + id, reqData);
}

export function getProfileData() {
  return apiProvider.getAll(urlConstants.GET_PROFILE);
}
