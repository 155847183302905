export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/v1/';
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const GET_PROFILE = "auth/profile";

export const CHANGE_PASSWORD = "auth/change-password";

export const MASTER_DATA = "common/master-data";
export const SYSTEM_DATA = "common/system-data";

export const DEPARTMENTS_ENDPOINT = "clients/departments";

export const CLIENT_ENDPOINT = "client";
export const ADD_CLIENT = "client/create";
export const CLIENT_DETAILS = "client/details/";

export const CLIENT_USERS = "clients/users";
export const ASSIGN_USERS = "clients/users/departments/add";

export const CLIENT_ROLES = "clients/roles";
export const UPLOAD_IMAGE = "resource/images-upload";

export const ROLE_PERMISSION = "clients/roles/permissions/";

export const CLIENT_ROLE_PERMISSION = "clients/roles/permission";

export const CLIENT_ATTENDANCE = "clients/attendance";

export const UPDATE_PROFILE = "profile/update";

export const CLIENT_SETTINGS = "clients/settings/";

export const IMPORT_EXCEL = "clients/users/excel/import";

export const DASHBOARD = "dashboard";

export const DASHBOARD_REPORTS = "dashboard/reports/";

export const CHANGE_USER_STATUS = "clients/users/status/update/";
export const CHANGE_DEPARTMENT_STATUS = "clients/departments/status/update/";

export const CHANGE_CLIENT_STATUS = "client/status/update/";

export const UPDATE_USER_NOT_MATCHED_IMAGE = "clients/users/update/main-image/";

export const REPORTS = "clients/reports";
