// response.js
import * as commonServices from './common';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED, ERROR_CODE, EMAIL_UNVERIFIED, SERVER_ERROR } from '../Constants/responseCodes';
import { warn } from '../Wrapper/toast/toast';
import { LOGIN_AGAIN } from '../Constants/stringConstants';
import { trackAPITime } from '../Helpers/analytics';

export function handleResponse(response, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, SUCCESS_CODE);

    if (response?.data?.code === SUCCESS_CODE || response?.data === RESOURCE_CREATED) {
        response.data.isSuccess = true;
    } else if (response.data.code === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        warn(LOGIN_AGAIN);
        return;
    }
    else {
        response.data.isSuccess = false;
    }
    return response?.data;
}

export function handleError(error, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, "ERROR");

    if (error?.response?.data?.code === ERROR_CODE || error?.response?.data?.code === EMAIL_UNVERIFIED || error?.response?.data?.code === SERVER_ERROR) {
        error.response.data.isSuccess = false;
        return error.response.data;
    }
    else if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        warn(LOGIN_AGAIN);
        return;
    }
}
