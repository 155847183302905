import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from './common';
import * as urlconstant from '../Constants/urlConstants';

const BASE_URL = urlconstant.BASE_URL;
const cancelTokenMap = {};

// Function to get headers
export const getHeaders = (applyUniqueCode = true) => {
  const userData = commonServices.getLocalData(storageConstants.USER_DATA);
  const uniqueCode = userData?.code?.toUpperCase() ?? '';

  let headers = {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.USER_DATA)?.auth !== null ? `Bearer ${commonServices.getLocalData(storageConstants.USER_DATA)?.auth}` : null,
  };

  if (applyUniqueCode) headers['x-system-code'] = uniqueCode;

  return headers;
};

/**
 * Cancel previous request if it exists and is still pending
 * @param {string} resource
 */
const cancelPreviousRequest = (resource) => {
  if (cancelTokenMap[resource]) {
    cancelTokenMap[resource].cancel('Request cancelled due to new request.');
  }
  cancelTokenMap[resource] = axios.CancelToken.source();
};

/** @param {string} resource */
export const getAll = (resource, model = '', applyUniqueCode = true) => {
  cancelPreviousRequest(resource);

  const headers = getHeaders(applyUniqueCode);
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${model}`;

  return axios
    .get(url, { headers, cancelToken: cancelTokenMap[resource].token })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const get = (resource, id, applyUniqueCode = true) => {
  const headers = getHeaders(applyUniqueCode);
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${id}`;

  return axios
    .get(url, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const post = (resource, model, applyUniqueCode = true) => {
  const headers = getHeaders(applyUniqueCode);
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}`;

  return axios
    .post(url, model, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const put = (resource, model, applyUniqueCode = true) => {
  const headers = getHeaders(applyUniqueCode);
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}`;

  return axios
    .put(url, model, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};


export const deleteAPI = (id, applyUniqueCode = true) => {
  const headers = getHeaders(applyUniqueCode);
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${id}`;

  return axios
    .delete(url, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};
